/*! _colors.scss | Kobail | 2019 */

/* ==========================================================================
Kobail colors
========================================================================== */

$primary: #F9681E;
$primary-light: #FF8745;
$primary-bold: #e84f1e;
$accent: #3585c4;

$white: #fff;
$smoke-white: #fafafa;
$muted-grey: #999;
$fade-grey: #ededed;
$med-grey: #6f7592;
$section-grey: #e8eff9;
$placeholder: #cecece;
$dark-text: #332d55;
$grey-light: #EDF1FA;
$grey-purp: #ddd9ed;

$red: #FF7273;
$blue: #039BE5;
$green: #00b289;
$orange: #eda514;

/* ==========================================================================
Shadow variables
========================================================================== */

//Base shadow
$base-shadow: rgba(0, 0, 0, 0.12);

//Light box shadow
$light-box-shadow: -1px 3px 15px 0 rgba(0, 0, 0, 0.06);

//Primary box shadow
$primary-shadow-from: rgba($primary, 0.42);
$primary-shadow-to: rgba($primary, 0.2);
$primary-box-shadow: 0 14px 26px -12px $primary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $primary-shadow-to !important;

//Accent box shadow
$accent-shadow-from: rgba($accent, 0.42);
$accent-shadow-to: rgba($accent, 0.2);
$accent-box-shadow: 0 14px 26px -12px $accent-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $accent-shadow-to !important;
