/*! _utilities.scss | Kobail | 2019 */

/* ==========================================================================
CSS utilities
========================================================================== */

var {
    font-style: normal;
}

//Hidden modifier
.is-hidden {
    display: none !important;
}

//Visibilty modifier
.is-vhidden {
    visibility: hidden !important;
}

//Pointer events none modifier
.no-click {
    pointer-events: none !important;
}

//disabled modifier
.is-disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: default !important;
}

//Margin left auto (flex helper)
.ml-auto {
    margin-left: auto !important;
}

//Scrollbar
.has-slimscroll {
    &::-webkit-scrollbar {
        width: 6px !important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
        background: rgba(0, 0, 0, 0.2) !important;
    }
}

.has-slimscroll-sm {
    &::-webkit-scrollbar {
        width: 3px !important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
        background: rgba(0, 0, 0, 0.2) !important;
    }
}

//Dividers
.divider-container {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .divider-bar {
        height: 160px;
        width: 1.4px;
        background: lighten($muted-grey, 25%);
    }

    .divider-text {
        font-weight: 600;
        color: $med-grey;
        margin: 16px;
    }
}

/* ==========================================================================
Back to top button
========================================================================== */

#backtotop {
    position: fixed;
    right: 0;
    opacity: 0;
    visibility: hidden;
    bottom: 25px;
    margin: 0 25px 0 0;
    z-index: 9999;
    transition: 0.35s;
    transform: scale(0.7);
    transition: all 0.5s;
}

#backtotop.visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

#backtotop.visible a:hover {
    outline: none;
    opacity: 0.9;
    background: $primary-bold;
}

#backtotop a {
    outline: none;
    text-decoration: none;
    border: 0 none;
    display: block;
    width: 46px;
    height: 46px;
    background-color: $primary-bold;
    opacity: 1;
    transition: all 0.3s;
    border-radius: 50%;
    text-align: center;
    font-size: 26px;
}

body #backtotop a {
    outline: none;
    color: #fff;
}

#backtotop a:after {
    outline: none;
    content: "\f106";
    font-family: "FontAwesome";
    position: relative;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%);
}

/* ==========================================================================
Typed js
========================================================================== */

//Cursor animation
.typed-cursor {
    display: inline-block;
    opacity: 1;
    font-weight: 100;
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    -ms-animation: blink 0.7s infinite;
    -o-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* ==========================================================================
Fade In Left
========================================================================== */

@-webkit-keyframes fadeInLeft {
    from {
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes fadeInLeft {
    from {
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.preFadeInLeft {
    opacity: 0;
}

.fadeInLeft {
    opacity: 0;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

/* ==========================================================================
Animated Checkmark
========================================================================== */

$circle-length: 151px;
$check-length: 36px;

@keyframes scaleAnimation {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes drawCircle {
    0% {
        stroke-dashoffset: $circle-length;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes drawCheck {
    0% {
        stroke-dashoffset: $check-length;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

#successAnimationCircle {
    stroke-dasharray: $circle-length $circle-length;
    stroke: $primary;
}

#successAnimationCheck {
    stroke-dasharray: $check-length $check-length;
    stroke: $primary;
}

#successAnimationResult {
    fill: $primary;
    opacity: 0;
}

#successAnimation.animated {
    animation: 1s ease-out 0s 1 both scaleAnimation;

    #successAnimationCircle {
        animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
    }

    #successAnimationCheck {
        animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
    }

    #successAnimationResult {
        animation: 0.3s linear 0.9s both fadeIn;
    }
}

.no-click-svg {
    pointer-events: none;

    * {
        pointer-events: none;
    }
}

.has-popover {
    position: relative;
    cursor: pointer;
}

.webui-popover {
    max-width: 280px;

    .webui-popover-content {
        font-size: .9rem;
    }
}


.hamster-stack {
    position: relative;
    height: 300px;
    width: 300px;

    img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        transition: all .3s;

        &.front {
            z-index: 2;
        }

        &.back {
            z-index: 1;

            &.is-pulled {
                transform: translateY(-40px);
            }
        }
    }
}
