/*! _forms.scss | Kobail | 2019 */

/* ==========================================================================
Kobail form controls
========================================================================== */

.field {
    .control {
        position: relative;

        &.is-loading {
            .validation-icon {
                display: none !important;
            }
        }

        &.has-validation {
            &.has-success {
                .validation-icon {
                    &.is-success {
                        display: flex;
                    }

                    &.is-error {
                        display: none;
                    }
                }

                + .error-text {
                    display: none;
                }
            }

            &.has-error {
                .validation-icon {
                    &.is-success {
                        display: none;
                    }

                    &.is-error {
                        display: flex;
                    }
                }

                + .error-text {
                    display: block;
                }
            }

            .validation-icon {
                position: absolute;
                top: 0;
                right: 0;
                height: 40px;
                width: 40px;
                display: none;
                justify-content: center;
                align-items: center;

                &.is-success {
                    svg {
                        stroke: $primary;
                    }
                }

                &.is-error {
                    svg {
                        stroke: $primary;
                    }
                }

                svg {
                    height: 18px;
                    width: 18px;
                    transition: all .3s;
                }
            }
        }

        .input {
            box-shadow: none !important;
            height: 40px;
            padding-left: 40px;
            padding-right: 40px;
            transition: all .3s;

            &:focus {
                border-color: $primary;
            }

            &:focus + .form-icon {
                svg {
                    stroke: $primary;
                }
            }
        }

        .form-icon {
            position: absolute;
            top: 0;
            left: 0;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                height: 18px;
                width: 18px;
                stroke: $placeholder;
                transition: all .3s;
            }
        }
    }

    .error-text {
        display: none;
        font-size: .8rem;
        color: $red
    }

    .textarea {
        box-shadow: none !important;
        transition: all .3s;

        &:focus {
            border-color: $primary;
        }
    }
}
