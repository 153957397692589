/*! _layout.scss | Kobail | 2019 */

/* ==========================================================================
Kobail layout
========================================================================== */

body {
    overflow-x: hidden;
}

/* ==========================================================================
Landing Hero
========================================================================== */

//Hero
.hero-body {
    position: relative;

    .hero-content {
        position: relative;
        top: 30px;
    }
}

//Animation wrapper
.scene-wrap {
    position: relative;
    height: 400px;
    width: 400px;
    transform: scale(1.2);
    z-index: 1;
}

//Shape
.hero-square {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 85%;
    height: auto;
    z-index: 0;
}

//Hero caption
.hero-caption {
    position: relative;
    top: -40px;

    h1 {
        color: $smoke-white;

        small {
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 500;
        }

        span {
            &:nth-child(4) {
                font-weight: 900;
                font-size: 4rem;
            }
        }
    }

    h2 {
        color: darken($smoke-white, 4%);
    }

    .button-wrap {
        margin-top: 10px;
    }
}

/* ==========================================================================
About Hero
========================================================================== */

//Shape
.about-shape {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 75%;
    height: auto;
    z-index: 0;
}

//Caption
.about-caption {
    position: relative;
    top: -80px;

    h1 {
        color: $dark-text;
        font-size: 4rem;

        span {
            &:nth-child(4) {
                font-weight: 900;
                font-size: 6rem;
            }
        }
    }

    h2 {
        color: darken($dark-text, 4%);
    }

    .button-wrap {
        margin-top: 10px;
    }
}

.floating-button {
    position: absolute;
    top: 70%;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 50px;
    width: 50px;
    background: $white;
    border: 1px solid darken($fade-grey, 4%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: $light-box-shadow;
    z-index: 2;
    transition: all .3s;

    &:hover {
        border-color: $primary;

        svg {
            stroke: $primary;
        }
    }

    svg {
        height: 18px;
        width: 18px;
        stroke: $dark-text;
        transition: all .3s;
    }
}

/* ==========================================================================
Sections
========================================================================== */

.section {
    &.has-wave-bottom {
        position: relative;
        padding-bottom: 300px;

        &.is-bottom-lg {
            padding-bottom: 400px;
        }

        .wave-bottom {
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            z-index: 0;
        }
    }

    &.has-wave-top {
        position: relative;
        padding-top: 400px;

        .wave-top {
            display: block;
            position: absolute;
            top: -1px;
            left: 0;
            width: 100%;
            z-index: 0;
        }
    }

    &.has-hamster {
        position: relative;
        overflow: hidden;

        .is-hamster {
            display: block;
            position: absolute;
            bottom: -140px;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 390px;
        }
    }

    &.is-grey-light {
        background: $grey-light;
    }

    &.is-grey-purp {
        background: $grey-purp;
    }

    &.has-line-first {
        position: relative;

        &:before {
            content: '';
            width: 1.8px;
            height: 110px;
            background: $primary;
            position: absolute;
            top: -100px;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: rotate(180deg);
        }
    }

    &.has-line-before {
        position: relative;

        &:before {
            content: '';
            width: 1.8px;
            height: 60px;
            background: $primary;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: rotate(180deg);
        }
    }

    &.has-line-after {
        position: relative;

        &:after {
            content: '';
            width: 1.8px;
            height: 60px;
            background: $primary;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: rotate(180deg);
        }
    }

    .section-title {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: -60px;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 2px;
            width: 82px;
            background: $primary;
            transform: rotate(-55deg);
        }

        &:after {
            content: '';
            position: absolute;
            top: -80px;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 2px;
            width: 82px;
            background: $primary;
            transform: rotate(-55deg);
        }

        h2 {
            max-width: 740px;
            margin: 0 auto;
        }

        p {
            font-size: 1.2rem;
            color: $muted-grey;
            margin-top: 10px;
        }
    }
}

/* ==========================================================================
Features
========================================================================== */

.features {
    margin-top: 80px;

    .features-wrap, .feature-details {
        animation: fadeInLeft .5s;
    }

    .feature-details {
        display: none;
        position: relative;
        width: 100%;
        max-width: 860px;
        margin: 0 auto;
        background: $white;
        border-radius: 8px;
        border: 1px solid darken($fade-grey, 4%);
        padding: 30px;
        transition: all .3s;

        &.is-active {
            display: block;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .details-heading {
                display: flex;
                align-items: center;

                img {
                    display: block;
                    height: 40px;
                    width: 40px;
                }

                h3 {
                    font-size: 1.2rem;
                    font-weight: 600;
                    font-family: 'Open Sans', sans-serif;
                    color: $dark-text;
                    margin-left: 12px;
                }
            }

            .close-button {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 36px;
                width: 36px;
                border-radius: 50%;
                transition: background-color .3s;

                &:hover {
                    background: lighten($fade-grey, 3%);
                }

                svg {
                    height: 16px;
                    width: 16px;
                    color: $muted-grey;
                }
            }
        }

        .details-body {
            .columns {
                align-items: stretch;
            }

            .boxes {
                margin-top: 30px;

                .column {
                    padding: .35rem;
                }

                .process-box {
                    position: relative;
                    width: 100%;
                    min-height: 358px;
                    background: $white;
                    border-radius: 8px;
                    border: 1px solid darken($primary, 1%);
                    padding: 20px;
                    transition: all .3s;

                    .box-number {
                        position: absolute;
                        top: -17px;
                        right: 27px;
                        font-size: 4.5rem;
                        color: lighten($primary, 5%);
                        opacity: .6;
                        font-weight: bolder;
                    }

                    h3 {
                        font-weight: 500;
                        color: $dark-text;
                        margin-bottom: 30px;
                        position: relative;
                        padding-right: 3.5rem;
                        z-index: 1;
                    }

                    p {
                        color: $med-grey;
                        font-size: .85rem;
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }

            .table-wrap {
                width: 100%;
                margin-top: 16px;

                table {
                    width: 100%;

                    th {
                        font-weight: 700;
                        font-size: .8rem;
                        text-transform: uppercase;
                        color: $dark-text;

                        &:first-child {
                            width: 34%;
                        }

                        &:nth-child(2), &:nth-child(3) {
                            width: 33%;
                        }
                    }

                    td {
                        vertical-align: middle;

                        &.title {
                            font-weight: 500;
                            font-family: 'Open Sans', sans-serif;
                            font-size: .9rem;
                            color: $primary;
                            min-width: 100px;
                        }

                        &.td-content {
                            color: $med-grey;

                            small {
                                font-size: .75rem;
                                font-weight: 600;
                                color: $dark-text;
                            }
                        }
                    }
                }
            }

            .side-block {
                margin-top: 20px;

                h4 {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 1rem;
                    color: $dark-text;
                    font-weight: 500;
                    margin-bottom: 6px;
                }

                p {
                    color: $muted-grey;
                    font-size: .95rem;
                    margin-bottom: 8px;
                }

                .control {
                    margin-top: 16px;

                    input {
                        box-shadow: none !important;
                        transition: all .3s;
                        border-width: 1.4px;
                        margin-bottom: 10px;
                        height: 42px;

                        &:focus {
                            border-color: $primary;
                        }
                    }
                }
            }

            .doc-success, .doc-form {
                animation: fadeInLeft .5s;
            }

            .doc-success {
                h4 {
                    font-weight: 500;
                    color: $dark-text;
                    font-size: 1.1rem;
                }

                p {
                    max-width: 300px;
                    margin: 0 auto;
                }
            }
        }
    }
}

.feature-card {
    position: relative;
    width: 100%;
    background: $white;
    border-radius: 8px;
    border: 1px solid darken($fade-grey, 4%);
    padding: 30px;
    transition: all .3s;

    &.first {
        margin-top: 40px;
    }

    &.second {
        margin-top: -40px;
    }

    &.third {
        margin-top: 80px;
    }

    &.fourth {
        margin-top: 10px;
    }

    &:hover {
        border-color: $primary;
        transform: translateY(-5px);

        /*.more-button {
            opacity: 1;
            pointer-events: all;
            transform: rotate(0) scale(1);
        }*/
    }

    /*.more-button {
        position: absolute;
        top: 20px;
        right: -14px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $primary;
        opacity: 0;
        pointer-events: none;
        transform: rotate(360deg) scale(0);
        box-shadow: $primary-box-shadow;
        transition: opacity .3s, transform .3s, background-color .3s;

        &:hover {
            background: $med-grey;
        }

        svg {
            stroke: $smoke-white;
            height: 16px;
            width: 16px;
            stroke-width: 4px;
        }
    }*/

    .read-more-button {
        margin-top: 20px;
    }

    .card-icon {
        height: 70px;
        width: 70px;
        margin: 20px auto;

        img {
            display: block;
        }
    }

    h3 {
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        color: $dark-text;
    }

    .inner-content {
        padding-top: 20px;
        font-size: .9rem;
    }
}

/* ==========================================================================
Animated wheel
========================================================================== */

.services-wheel {
    position: relative;
    margin: 40px auto 0 auto;
    max-width: 600px;
    max-height: 600px;
    min-width: 600px;
    min-height: 600px;

    svg {
        max-width: 600px;
    }

    #big-wheel-main {
        position: absolute;
        top: 16px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    #big-wheel-wheel {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        z-index: 1;
        animation: drumspin 8s linear infinite;
    }
}

/* ==========================================================================
Pricing
========================================================================== */

.pricing-wrap {
    margin-top: 60px;

    .round-slider-wrap {
        position: relative;

        .tooltip {
            position: absolute;
            top: -50px;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.16);

            &__arrow {
                width: 50px;
                height: 25px;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                overflow: hidden;

                &::after {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background: white;
                    transform: translateX(-50%) translateY(-50%) rotate(45deg);
                    top: 0;
                    left: 50%;
                    box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.3);
                }
            }

            .tooltip-content {
                position: relative;
                height: 100%;
                width: 100%;
                padding: 20px;
            }
        }
    }

    #round-slider {
        margin: 0 auto;

        .rs-range-color {
            background-color: #F9681E;
        }

        .rs-handle:after {
            background-color: #F9681E;
        }

        .rs-border {
            border-color: #F9681E;
        }

        .rs-inner {
            background: $grey-purp;
        }

        .rs-path-color {
            background: $grey-purp;
        }

        .rs-tooltip {
            font-weight: bold;
            font-family: 'Fira Sans', sans-serif;
            font-size: 1.6rem;

            &:after {
                content: "€";
                margin-left: 3px;
            }
        }
    }

    .round-slider-output {
        display: flex;
        align-items: baseline;
        justify-content: center;
        margin-top: 30px;

        h3 {
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: $dark-text;
            margin-right: 20px;
        }

        span {
            display: flex;
            align-items: baseline;
            justify-content: center;

            var {
                font-family: 'Fira Sans', sans-serif;
                font-size: 2.4rem;
                font-weight: bold;
                color: $primary;

                &:after {
                    content: "€";
                    margin-left: 3px;
                }
            }

            small {
                font-family: 'Open Sans', sans-serif;
                font-weight: 600;
                color: $primary;
                margin-left: 6px;
            }
        }
    }

    .pricing-card {
        width: 100%;
        background: $white;
        border-radius: 8px;
        border: 1px solid darken($fade-grey, 4%);
        padding: 20px;

        &.is-kobail {
            img {
                margin-top: 10px;
            }

            .price-wrap {
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-top: 20px;

                span {
                    text-align: center;
                    text-transform: uppercase;
                    font-size: .8rem;
                    font-weight: 500;
                    color: $dark-text;
                    display: block;
                }

                .price {
                    margin-top: 0;
                }
            }

            .price {
                font-size: 2rem;
            }

            .offers {
                .offer {
                    .cost {
                        font-size: 1.2rem;
                    }
                }
            }
        }

        .card-icon {
            height: 70px;
            width: 70px;
            margin: 20px auto;

            img {
                display: block;
            }
        }

        h3 {
            text-align: center;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 1.2rem;
            color: $dark-text;
        }

        img {
            display: block;
            margin: 0 auto;
            height: 2.6rem;
        }

        .price {
            margin: 20px auto;
            font-size: 1.6rem;
            color: $primary;
            font-weight: 600;
            text-align: center;

            &:after {
                content: "€";
                margin-left: 3px;
            }
        }

        .per {
            display: block;
            text-align: center;
        }

        .offers {
            margin-top: 30px;
            padding-bottom: 30px;

            .offer {
                display: flex;
                align-items: center;

                //justify-content: space-between;
                padding: 8px 16px;
                border: 1px solid darken($fade-grey, 4%);
                border-radius: 6px;
                max-width: 280px;
                margin: 0 auto 14px auto;

                &.has-dot {
                    position: relative;

                    .or-dot {
                        position: absolute;
                        top: -28px;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        height: 38px;
                        width: 38px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: $primary;
                        border: 1px solid $primary;
                        box-shadow: $primary-box-shadow;

                        span {
                            color: $smoke-white;
                            font-size: .8rem;
                            font-weight: 600;
                        }
                    }
                }

                .cost {
                    font-size: 1.1rem;
                    color: $primary;
                    font-weight: bold;
                    color: $dark-text;
                    text-align: center;

                    /*&:after {
                        content: "€";
                        margin-left: 3px;
                    }*/
                }

                .service {
                    text-transform: uppercase;
                    font-size: .75rem;
                    font-weight: 500;
                    color: $muted-grey;
                    padding-left: 20px;
                }

                .price-value {
                    margin-left: auto;
                    font-size: 1.1rem;
                    color: $primary;
                    font-weight: bold;
                    color: $dark-text;

                    &:after {
                        content: "€";
                        margin-left: 3px;
                    }
                }
            }
        }
    }
}

/* ==========================================================================
Satisfied customers
========================================================================== */

.satisfaction-wrap {
    margin-top: 80px;

    img {
        display: block;
        margin: 0 auto;
        max-width: 380px;
    }

    .side-feature {
        h3 {
            font-family: 'Fira Sans', sans-serif;
            font-weight: bold;
            font-size: 1.8rem;
            color: $dark-text;
            margin-bottom: 4px;
        }

        p {
            font-size: 1.1rem;
            color: $muted-grey;
        }

        .button-wrap {
            margin-top: 16px;
        }
    }
}

.cta-box {
    position: relative;
    overflow: hidden;
    padding: 60px;
    border-radius: 18px;
    max-width: 760px;
    margin: 0 auto;
    background: $primary;
    box-shadow: $primary-box-shadow;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 200px;

    .cta-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }

    .box-content {
        position: relative;
        text-align: center;
        color: $smoke-white;
        z-index: 2;

        h3 {
            margin-bottom: 10px;
            color: $smoke-white;
        }

        p {
            max-width: 450px;
            margin: 0 auto 20px auto;
        }

        .button {
            text-transform: uppercase;
            font-size: .8rem;
            font-weight: 500;
            color: $dark-text;
            padding: 12px 24px;
            min-height: 38px;
        }
    }
}

/* ==========================================================================
Footer
========================================================================== */

.footer {
    position: relative;
    background: $grey-light;
    padding: 100px 0 140px 0;
    overflow: hidden;

    .tracks {
        display: block;
        position: absolute;
        bottom: -30px;
        right: -28px;
        height: 250px;
    }

    .footer-logo {
        display: block;
        max-width: 180px;
    }

    .socials {
        margin-top: 10px;

        a {
            padding: 0 5px;
            color: $med-grey;

            &:hover {
                i {
                    color: $primary-bold;
                }
            }

            i {
                font-size: 22px;
                transition: color .3s;
            }
        }
    }

    .footer-heading {
        font-family: 'Open Sans', sans-serif;
        color: $primary;
        font-weight: 600;
        font-size: 1.1rem;
        margin-bottom: 16px;
    }

    .footer-list {
        li {
            margin-bottom: 10px;

            a {
                color: $muted-grey;
                transition: color .3s;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

/* ==========================================================================
Floating circles
========================================================================== */

.orange-circle {
    position: absolute;
    border-radius: 50%;
    background: $primary;

    &.is-1 {
        top: 0;
        left: 12%;
        height: 40px;
        width: 40px;
    }

    &.is-2 {
        bottom: 260px;
        left: 50%;
        height: 40px;
        width: 40px;
    }

    &.is-3 {
        bottom: 400px;
        right: 10%;
        height: 60px;
        width: 60px;
    }

    &.is-4 {
        bottom: 280px;
        left: 10%;
        height: 20px;
        width: 20px;
    }
}

.grey-circle {
    position: absolute;
    border-radius: 50%;
    background: $grey-light;

    &.is-1 {
        bottom: 320px;
        left: 12%;
        height: 36px;
        width: 36px;
    }

    &.is-2 {
        bottom: 480px;
        right: 5%;
        height: 44px;
        width: 44px;
    }
}

.wheel-circle {
    position: absolute;

    //border-radius: 50%;
    //background: $grey-light;
    display: flex;
    justify-content: center;
    align-content: center;

    img {
        display: block;
        object-fit: contain;
        opacity: .5;
    }

    &.is-1 {
        top: 460px;
        left: 10%;
        height: 60px;
        width: 60px;
    }

    &.is-2 {
        top: 540px;
        left: 20%;
        height: 30px;
        width: 30px;
    }

    &.is-3 {
        top: 600px;
        left: 19%;
        height: 20px;
        width: 20px;
    }

    &.is-4 {
        bottom: 480px;
        left: 20%;
        height: 30px;
        width: 30px;
    }

    &.is-5 {
        bottom: 360px;
        left: 27%;
        height: 50px;
        width: 50px;
    }

    &.is-6 {
        bottom: 320px;
        left: 23%;
        height: 20px;
        width: 20px;
    }

    &.is-7 {
        top: 560px;
        right: 30%;
        height: 30px;
        width: 30px;
    }

    &.is-8 {
        top: 530px;
        right: 11%;
        height: 50px;
        width: 50px;
    }

    &.is-9 {
        bottom: 420px;
        right: 12%;
        height: 50px;
        width: 50px;
    }

    &.is-10 {
        bottom: 560px;
        right: 18%;
        height: 20px;
        width: 20px;
    }
}

/* ==========================================================================
About page styles
========================================================================== */

//Heading
.about-heading {
    &.is-spaced {
        margin-top: 40px;
    }
}

//Paragraph
.block-paragraph {
    margin: 40px auto 60px auto;
    max-width: 540px;
    text-align: left;
    color: $med-grey;

    + .block-paragraph {
        margin-top: -30px;
    }
}

//team
.team-wrap {
    margin-top: 60px;

    .team-block {
        text-align: center;

        img {
            display: block;
            height: 90px;
            width: 90px;
            border: 1.4px solid $primary;
            border-radius: 50%;
            margin: 0 auto;
        }

        h3 {
            margin-top: 6px;
            font-size: 1.1rem;
            color: $dark-text;
            font-weight: 500;
        }

        p {
            color: $primary;
            font-weight: 500;
            font-size: .9rem;
        }
    }
}

//Contact
.contact-info {
    .contact-block {
        display: flex;
        align-items: center;

        svg {
            height: 28px;
            width: 28px;
            stroke-width: 1px;
        }

        .meta {
            margin-left: 12px;
            text-align: left;

            span {
                display: block;

                &.label {
                    font-size: .9rem;
                    font-weight: 500;
                    color: $dark-text;
                    margin-bottom: 0;
                }

                &.text {
                    color: $muted-grey;
                }
            }
        }
    }
}

//Form
.contact-form {
    min-height: 362px;
    width: 100%;
    max-width: 740px;
    margin: 40px auto;
    padding: 40px;
    background: $white;
    border: 1px solid darken($fade-grey, 4%);
    border-radius: 8px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;

    .success-inner {
        text-align: center;
        animation: fadeInLeft .5s;

        h4 {
            font-weight: bold;
            color: $dark-text;
            font-size: 1.3rem;
        }

        p {
            max-width: 380px;
            margin: 0 auto;
        }
    }
}

/* ==========================================================================
TOS page styles
========================================================================== */

.term-block {
    margin-bottom: 40px;

    h3 {
        font-family: 'Fira Sans', sans-serif;
        font-weight: 500;
        color: $primary;
        font-size: 1.6rem;
        margin-bottom: 20px;
    }

    h4 {
        font-family: 'Fira Sans', sans-serif;
        font-weight: 500;
        color: $primary;
        font-size: 1.3rem;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 12px;
    }

    ul {
        list-style-type: disc;
        padding-left: 20px;
    }
}
