$theme: #3C8EBD;
$theme-txt: contrast-text-color($theme);

$blue-m: #3C8EBD;
$blue-l: lighten($blue-m, 10%);
$blue-d: darken($blue-m, 10%);

$grey-m: #e7e7e7;
$grey-l: lighten($grey-m, 5%);
$grey-d: darken($grey-m, 10%);

$tan-l: #FFB530;
$tan-m: darken($tan-l, 20%);
$tan-d: darken($tan-l, 50%);

.scene-wrap {
    .scene * {
        display: block;
    }

    .scene {
        position: absolute;
        left: 5vw;
    }

    .wheel * {
        background: $grey-m;
    }

    .wheel {
        position: absolute;
        top: 0;
        left: 0;
    }

    .drum {
        width: 20em;
        height: 20em;
        border-radius: 50%;
        background: transparent;
        border: 8px solid $grey-m;
        position: relative;
        left: .2em;
    }

    .drum::after {
        display: block;
        content: '';
        width: 20em;
        height: 20em;
        border-radius: 50%;
        background: transparent;
        border: 10px dotted #f4f4f4;
        position: relative;
        top: -41px;
        left: -9px;
        z-index: 0;
    }

    .bar {
        width: 19em;
        height: 2em;
        position: relative;
        top: 44%;
    }

    .nut {
        background: $grey-d;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: absolute;
        top: 45%;
        left: 39%;
        z-index: 20;
    }

    .stand {
        width: 14em;
        height: 14em;
        background: transparent;
        border: 8px solid $grey-l;
        border-width: 8px 0 0 8px;
        transform: rotate(45deg);
        position: absolute;
        top: 60%;
        left: 13%;
    }

    .wheel::after {
        display: block;
        content: '';
        background: rgba(0, 0, 0, 0.02);
        width: 25em;
        height: 1em;
        border-radius: 100%;
        position: relative;
        left: -2em;
        top: -.5em;
        z-index: -10;
    }

    .hamster {
        position: absolute;
        top: -1em;
        left: 6em;
        z-index: -10;
        width: 10em;
        height: 20em;
    }

    .body {
        width: 2em;
        height: 5em;
        z-index: 0;
        position: absolute;
        left: 3em;
        background: $tan-l;
    }

    .body::before,
    .body::after {
        background: $tan-l;
        content: "";
        width: 5em;
        height: 5em;
        border-radius: 50%;
        position: absolute;
        z-index: -10;
    }

    .body::before {
        top: 0;
        left: -2.5em;
    }

    .body::after {
        top: 0;
        right: -2.5em;
    }

    .face {
        perspective: 50px;
    }

    .chin {
        background: $tan-l;
        width: 2em;
        height: 2em;
        border-radius: 0 0 0 100%;
        position: absolute;
        top: 1.8em;
        left: -3.6em;
        transform: scaleX(0.6);
    }

    .chin::before {
        display: block;
        content: '';
        background: #fff;
        width: 0.3em;
        height: 0.3em;
        border-radius: 100%;
    }

    .chin::after {
        display: block;
        content: '/|\\';
        color: #fff;
        transform: rotate(-60deg);
        position: relative;
        top: -0.6em;
        left: 1em;
    }

    .eyes {
        background: $tan-d;
        width: 0.6em;
        height: 0.6em;
        border-radius: 100%;
        position: relative;
        top: 1.4em;
        left: -1em;
    }

    .ears {
        background: $tan-d;
        width: 2.4em;
        height: 2em;
        border-radius: 0 40% 40% 0;
        transform: translate3d(0, 0, 0);
        transform: rotateY(-40deg) scale(0.8);
    }

    .legs * {
        background: $tan-l;
        width: 0.8em;
        height: 1.5em;
        border-radius: 50%;
        box-shadow: 3px 0 0 $tan-m;
        z-index: -15;
    }

    .legs .front {
        position: absolute;
        bottom: -0.8em;
        left: -1.2em;
        transform: rotate(6deg);
    }

    .legs .back {
        position: absolute;
        bottom: -0.7em;
        right: -1.5em;
        transform: rotate(-6deg);
    }

    .tail {
        background: $tan-l;
        width: 1em;
        height: 0.3em;
        border-radius: 100%;
        position: absolute;
        top: 1.4em;
        right: -2.6em;
        transform: rotate(-12deg);
    }

    //Animations
    .drum {
        animation: drumspin 3s linear reverse infinite;
    }

    .legs *, .tail {
        animation: legmove 0.3s ease-in  infinite;
    }

    .legs .front {
        animation-delay: 0.1s;
    }

    .body {
        animation: hamsterbounce 0.3s ease-in-out alternate infinite;
    }

    $easing: cubic-bezier(0.65, 0, 0.38, 0.775);

    //$easing: linear;

    .hamster {
        animation: hamsterswing 2s $easing infinite;
    }
}

@keyframes drumspin {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes legmove {
    0% {
        transform: rotate(32deg);
    }

    80% {
        transform: rotate(-32deg);
    }

    100% {
        transform: rotate(32deg);
    }
}

@keyframes hamsterbounce {
    0% {
        bottom: 0.6em;
        transform: scaleY(1);
    }

    50% {
        bottom: 0;
        transform: scaleY(0.94);
    }

    100% {
        bottom: 0.6em;
        transform: scaleY(1);
    }
}

@keyframes hamsterswing {
    // 0%    { transform:rotate(-6deg); }
    // 90%  { transform:rotate(24deg); }
    // 100%  { transform:rotate(-6deg); }
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(24deg);
    }

    70% {
        transform: rotate(-12deg);
    }

    100% {
        transform: rotate(0deg);
    }
}
