/*! _fonts.scss | Kobail | 2019 */

/* ==========================================================================
Kobail text
========================================================================== */

.title {
    font-family: 'Fira Sans', sans-serif;
    font-weight: bold;
    color: $dark-text;
}

.subtitle {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}