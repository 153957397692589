/*! _buttons.scss | Kobail | 2019 */

/* ==========================================================================
Kobail buttons
========================================================================== */

.button {
    transition: all .3s;

    &.cta-button {
        font-weight: 500;
        height: 50px;
        min-width: 150px;

        &.is-long {
            min-width: 180px;
        }
    }

    &.is-raised:hover {
        opacity: .9;
    }

    &.bold-button {
        background: $primary-bold;
        border-color: $primary-bold;
        color: $smoke-white;

        &.is-raised:hover {
            box-shadow: $primary-box-shadow;
        }
    }

    &.primary-button {
        background: $primary;
        border-color: $primary;
        color: $smoke-white;

        &.is-raised:hover {
            box-shadow: $primary-box-shadow;
        }
    }
}
