/*! _responsive.scss | Kobail | 2019 */

/* ==========================================================================
Venuepass responsive styles
========================================================================== */

/* ==========================================================================
max-width 767px
========================================================================== */

@media (max-width: 767px) {
    .navbar {
        &.is-faded {
            .navbar-brand {
                .light-logo {
                    &.is-desktop {
                        display: none !important;
                    }

                    &.is-mobile {
                        display: block !important;
                    }
                }
            }

            .cta-button {
                background: $primary !important;
                border-color: $primary !important;

                &:hover {
                    box-shadow: $primary-box-shadow !important;
                    opacity: .9 !important;
                }
            }

            &.is-alt {
                .navbar-brand {
                    .navbar-burger {
                        span {
                            background: $dark-text !important;
                        }
                    }

                    .light-logo {
                        display: none !important;
                    }

                    .dark-logo {
                        display: block !important;
                    }
                }

                .navbar-menu {
                    .cta-button {
                        color: $white;
                    }
                }
            }
        }
    }

    .hero-square {
        width: 100%;
        transform: scale(2);
    }

    .about-shape {
        width: 100%;
        transform: scale(16);
        margin-top: -280px;
    }

    .scene-wrap {
        height: 298px;
        width: 328px;
        transform: scale(0.7);
        margin: 0 auto;
        left: -40px;
        top: 5px;
    }

    .hero-caption {
        text-align: center;

        h1, h2 {
            color: $dark-text;
        }

        h2 {
            font-size: 1.1rem !important;
        }

        small {
            font-size: 1rem !important;
        }
    }

    .hamster-stack {
        margin: 0;
        transform: scale(0.75);
    }

    .about-caption {
        h1, h2 {
            color: $smoke-white !important;
        }
    }

    .section {
        &.has-wave-bottom {
            padding-bottom: 100px !important;
        }

        &.has-wave-top {
            padding-top: 210px !important;
        }
    }

    .section-title {
        h2 {
            font-size: 1.6rem !important;
        }
    }

    .features {
        margin-top: 40px;

        .feature-card {
            margin-top: 0;
            margin-bottom: 10px;
        }
    }

    .services-wheel {
        margin-bottom: 60px;
        max-width: 320px;
        max-height: 320px;
        min-width: 320px;
        min-height: 320px;
        transform: scale(1.1);
    }

    .round-slider-wrap {
        margin-top: 130px;
    }

    .satisfaction-wrap {
        margin-top: 30px;

        img {
            max-width: 270px;
        }

        .side-feature {
            text-align: center;

            h3 {
                font-size: 1.4rem;
            }
        }
    }

    .cta-box {
        margin-bottom: 200px;
    }

    .is-hamster {
        height: 260px !important;
        bottom: -86px !important;
    }

    .footer {
        padding: 40px !important;
        padding-top: 60px !important;
    }

    .orange-circle {
        &.is-2 {
            bottom: 232px !important;
            right: 6% !important;
            left: unset !important;
            height: 20px !important;
            width: 20px !important;
        }

        &.is-3 {
            bottom: 293px !important;
            right: -10% !important;
        }
    }

    .grey-circle {
        &.is-1 {
            bottom: 59px !important;
            left: 12% !important;
            height: 16px !important;
            width: 16px !important;
        }

        &.is-2 {
            bottom: 184px !important;
            right: 5% !important;
            height: 34px !important;
            width: 34px !important;
        }
    }

    .wheel-circle {
        &.is-1 {
            left: -4%;
            height: 30px;
            width: 30px;
        }

        &.is-2 {
            height: 10px;
            width: 10px;
        }

        &.is-3 {
            top: 732px;
            left: 20%;
            height: 20px;
            width: 20px;
        }

        &.is-5, &.is-8 {
            display: none;
        }

        &.is-6 {
            height: 16px;
            width: 16px;
        }

        &.is-7 {
            height: 18px;
            width: 18px;
        }

        &.is-9 {
            bottom: 441px;
            right: 3%;
            height: 40px;
            width: 40px;
        }
    }

    .contact-form {
        padding: 30px 10px;
    }
}

/* ==========================================================================
Tablet portrait orientation
========================================================================== */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .navbar {
        &.is-faded {
            .navbar-brand {
                .light-logo {
                    &.is-desktop {
                        display: block !important;
                    }

                    &.is-mobile {
                        display: none !important;
                    }
                }
            }

            .cta-button {
                background: $primary !important;
                border-color: $primary !important;

                &:hover {
                    box-shadow: $primary-box-shadow !important;
                    opacity: .9 !important;
                }
            }
        }
    }

    .navbar-menu {
        .button {
            max-width: 500px;
        }
    }

    .scene-wrap {
        margin: 0 auto;
        left: -40px;
        top: 5px;
    }

    .hamster-stack {
        margin: 0 auto 60px auto;
    }

    .hero-caption {
        text-align: center;

        h1, h2 {
            color: $dark-text;
        }

        h2 {
            font-size: 1.1rem !important;
        }

        small {
            font-size: 1rem !important;
        }
    }

    .about-shape {
        width: 100%;
        transform: scale(5);
        margin-top: -280px;
    }

    .about-caption {
        top: 40px;
        text-align: center;
    }

    .section {
        &.has-wave-bottom {
            padding-bottom: 300px !important;
        }

        &.has-wave-top {
            padding-top: 300px !important;
        }
    }

    .features {
        margin-top: 40px;

        .features-wrap {
            display: flex;
            flex-wrap: wrap;

            .column {
                min-width: calc(50% - 1.5rem) !important;
            }
        }

        .feature-card {
            margin-top: 0;
            margin-bottom: 10px;
        }
    }

    .round-slider-wrap {
        margin-top: 130px;
    }

    .pricing-card {
        max-width: 300px;
        margin: 0 auto;
    }

    .satisfaction-wrap {
        margin-top: 30px;

        img {
            max-width: 270px;
        }

        .side-feature {
            text-align: center;

            h3 {
                font-size: 1.4rem;
            }

            p {
                max-width: 460px;
                margin: 0 auto;
            }
        }
    }

    .cta-box {
        margin-bottom: 200px;
    }

    .is-hamster {
        height: 260px !important;
        bottom: -86px !important;
    }

    .footer {
        padding: 40px !important;
        padding-top: 60px !important;
    }

    .contact-info {
        .columns {
            display: flex;
            max-width: 690px !important;
            margin: 0 auto !important;
        }
    }

    .contact-form {
        max-width: 600px;

        .form-inner {
            width: 100%;
        }
    }
}

/* ==========================================================================
Tablet Landscape orientation
========================================================================== */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .navbar {
        &.is-faded {
            .navbar-brand {
                .light-logo {
                    &.is-desktop {
                        display: block !important;
                    }

                    &.is-mobile {
                        display: none !important;
                    }
                }

                .dark-logo {
                    display: none !important;
                }
            }

            .cta-button {
                background: $primary !important;
                border-color: $primary !important;

                &:hover {
                    box-shadow: $primary-box-shadow !important;
                    opacity: .9 !important;
                }
            }
        }
    }

    .navbar-menu {
        .button {
            max-width: 500px;
        }
    }

    .about-shape {
        width: 90% !important;
    }

    .cta-box {
        margin-bottom: 200px;
    }

    .is-hamster {
        height: 260px !important;
        bottom: -86px !important;
    }

    .footer {
        padding: 40px !important;
        padding-top: 60px !important;
    }
}
